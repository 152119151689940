/* header */
body {
    margin: 0;
    padding: 0;
}

.header-color {
    background-color: rgb(30, 185, 183);
    /* width: 100%; */
}


/* HomeLeft */

.home__box {
    background-color: #fff;
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 2px;
}

.home__title {
    color: rgb(30, 183, 185);
    font-weight: bold;
    margin-bottom: 1rem;
    font-size: 20px;
}

/* HomeRight */
.widgets {
    /* position: sticky; */
    top: 80px;
    flex: 0.1;
    background-color: white;
    border-radius: 2px;
    border: 1px solid lightgray;
    height: fit-content;
    padding: 10px;
}

.widgets__header {
    display: flex;
    /* align-items: center; */
    /* justify-content: space-evenly;    */
    padding-left: 10px;
    cursor: pointer;
}

.widgets__header>h2 {
    font-weight: bold;
    font-size: 18px;
    color: rgb(30, 185, 183);
}

.Widgets__article {
    display: flex;
    padding: 5px;

}

.widgets__articleLeft {
    color: #0177b7;
    margin-right: 5px;
}

.widgets__articleLeft>.MuiSvgIcon-root {
    font-size: 15px;
}

.widgets__articleRight {
    flex: 1;
}

.widgets__articleRight>h4 {
    font-size: 14px;
    padding: 5px;
    margin: 5px;
    cursor: pointer;

}

.widgets__articleRight>p {
    font-size: 12px;
    color: gray;
}


.circle {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    display: inline-block;
    background-repeat: no-repeat;
    border: 1px solid #188a88;
    background-size: 100px 100px;

}

.rectangle {
    height: 90px;
    width: 90px;
    border-radius: 3%;
    /* display: inline-block; */
    /* background-repeat: no-repeat; */
    background-color: #FAFAFA;
    border: 1px solid #F0F0F0;
    background-size: 80px 80px;


}

/* .row{
    --bs-gutter-x: 0px !important;
} */
.list {
    cursor: pointer;
}



/* Modal profile Upload */



.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    width: 84px;
    height: 70px;
}

.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

.example-modal {
    background-color: #F0F0F0;
    padding: 15px;
    display: inline;

}

.info-modal {
    background-color: #f5de7a63;
    padding: 15px;
    color: #937d1e;
    display: inline;
}

.calender-icon {
    float: right;
    cursor: pointer;
    font-size: 23px;
    color: #11100f96;
}



/* Search Icon in Navbar */
.has-search .form-control {
    padding-left: 2.375rem;
}

.has-search {
    position: relative;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
    top: 2px;
}

.navbar {
    padding: 0px !important;
}

.home-provision {
    color: rgb(73 68 68);
    background-color: #F0F0F0;
}

.home-new-btn {
    /* float: right; */
    color: orange;
    cursor: pointer;

}

.home-new-btn:hover {
    border-bottom: 2px solid orange;
    ;
}

.hyper-link {
    border-bottom: 2px solid white;
    margin-bottom: 12px;
    /* background-color:"red" */
}

/* .hyper-link:hover{
    border-bottom: 2px solid red;
} */
.Widgets__article:hover .hyper-link {
    background-color: whitesmoke;
    border-bottom: 1px solid red;

}

.Widgets__article h4:hover {
    background-color: whitesmoke;

}

.agent-dropdown-filter {
    height: 200px;
    width: 100%;
    overflow-y: auto;
    background-color: white;
    cursor: pointer;
    border: 1px solid #ECECEC;
    padding-left: 12px;
    position: absolute;
    top: 41px;
    z-index: 100;
}

.search-dropdown-parent {
    margin-left: -14px;
}

.search-dropdown-parent:hover {
    background-color: #F0F0F0;
}

.table-item {
    font-size: 13px;
    font-weight: 600;
}

.filter-title {
    font-size: 13px;
}

.filter-ldap {
    font-size: 13px;
}

.search-dropdown {
    color: #212529;
    padding: 10px;
    padding-left: 0px;
}


.dropper-margin {
    margin-top: 90px;
}

.dropper-margin-remove {
    margin-top: 20px;

}

.color-info {
    background-color: #cce3f0;
}

.person-dropdown {
    height: 200px;
    width: 85%;
    overflow-y: auto;
    background-color: white;
    cursor: pointer;
    border: 1px solid #ECECEC;
    padding: 0px;
    z-index: 100;
}

.person-dropdown-row {
    color: #212529;
    cursor: pointer;
    text-align: start;
    margin: 2px 0;
}