.admin-switch {}

.admin-switch .form-check-input:checked {
  background-color: rgb(30, 183, 185);
  border-color: rgb(30, 183, 185);
}

.admin-switch .form-check-input {
  background-color: rgb(220, 53, 69);
  border-color: rgb(220, 53, 69);
}

.admin-switch .form-check-input {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.manage-roster {}

.manage-roster-accordion {}

.manage-roster-accordion .add-new {
  color: rgb(30, 183, 185);
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
}

.manage-roster-accordion .body-content {
  max-height: 350px;
  overflow-y: auto;
}

.manage-roster-accordion .body-content .item-name {
  font-weight: 600;
  color: #000;
  /* min-width: 200px; */
}

.manage-roster-accordion .body-content .admin-switch {
  /* min-width: 25px;
  max-width: 25px; */
}

.manage-roster-accordion .body-content .item-desc {
  font-weight: 400;
  color: #b6b7b8;
}

.manage-roster-accordion .accordion-item {
  margin-bottom: 15px;
  box-shadow: 1px 4px 3px -2px rgba(0, 0, 0, 0.2),
    0 1px 4px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
}

.manage-roster-accordion .accordion-header {}

.manage-roster-accordion .accordion-header .collapsed {
  color: #000000;
  font-weight: 600;
  background-color: #fff;
}

.manage-roster-accordion .accordion-header .accordion-button {
  /* background-color: rgb(30, 183, 185); */
  font-weight: 600;
}

.manage-roster-accordion .accordion-header .accordion-button:not(.collapsed) {
  background-color: rgb(30, 183, 185);
  color: #fff;
  font-weight: 600;
  font-size: 1rem;
}

.manage-roster-accordion .accordion-body {
  background-color: rgb(30, 183, 185, 0.1);
}

.manage-roster-accordion ul {
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0px;
}

.manage-roster-accordion ul li {
  background-color: #ffffff;
  padding: 15px 15px 15px 30px;
  margin-bottom: 0.6rem;
}

.manage-titles .form-check-input:disabled~.form-check-label,
.manage-titles .form-check-input[disabled]~.form-check-label,
.manage-roles .form-check-input:disabled~.form-check-label,
.manage-roles .form-check-input[disabled]~.form-check-label {
  opacity: 1;
}