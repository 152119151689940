.select-box {
    border: 1px solid lightgray !important;
    /* border-radius: 2px; */
    background-image: url(../image/551749-200.png);
    padding: 3px 8px;
}
.text-color {
    color: #0d8b89;
}

.info-table-dataentry {
    background-color: #1EB9B7;
    padding: 15px;
    color: white;
    /* display: inline; */
    font-weight: 500;
}
.dataentry-modal{
    background-color: #b4dff8;
    padding: 15px;
    color: white;
    /* display: inline; */
    color: #0b6081;
    font-weight: 500;;
}
.datatable-non-changevalue{
    width: 100px;
    background-color:#F0F0F0;
    border:none; 
}
.color-red{
    color:red;
}
.btn-theme-err{
    margin-left:50px;
    background-color:blue !important;
}