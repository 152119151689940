::-webkit-scrollbar {
  width: 15px;
  margin: 3px;
  border-left: 1px solid rgba(50, 52, 65, 0.1);
  border-right: 1px solid rgba(50, 52, 65, 0.1);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(50, 52, 65, 0.15);
  border: 4px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-track {
  width: 15px;
}

::-webkit-scrollbar-corner {
  display: none;
  height: 0px;
  width: 0px;
}

.btn-theme {
  background-color: #1eb9b7 !important;
  border: none !important;
}

.fw-14 {
  font-size: 14px;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
  background-color: #212529 !important;
  color: white !important;
}

/* .dropdown-item {
 color:white !important;
} */
.dropdown-item:focus,
.dropdown-item:hover {
  background-color: #f0f0f0;
  color: black !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #f0f0f0;
  color: black !important;
}

.NavDropdown.Item {
  color: wheat !important;
}

.navdropdown-color {
  color: white;
}

.input-container-table {
  display: -ms-flexbox;
  /* IE10 */
  display: flex;
  width: 100%;
  margin-bottom: 15px;
}

.icon-text {
  padding: 10px;
  background: #f0f0f0;
  color: black;
  min-width: 50px;
  text-align: center;
}

.input-field:focus {
  border: 2px solid dodgerblue;
}

.location-userdatas {
  /* color: grey; */
  font-size: 19px;
  /* background-color: #f8f8f8; */
  margin: 0 0 20px 0;
  padding: 1px 0px 1px 13px;
  border-radius: 4px;
  /* width: 270px; */
  height: 32px;
}

.loader {
  text-align: center;
  padding: 200px 0;
}

.clear-btn {
  position: absolute;
  left: 14px;
}

/* for login page */

.login-container {
  position: absolute;
  height: 100vh;
  background: linear-gradient(to right, #0d8b89, #5c97be);
}

.loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url("../image/balls_loading.gif") 50% 50% no-repeat
    rgb(0, 0, 0, 0.5);
}
.text-pointer {
  cursor: pointer;
}
/* for attendate date filter */
.customDatePickerWidth,
.customDatePickerWidth > div.react-datepicker-wrapper,
.customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  .customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  input {
  width: 100%;
}

.round {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.tOMwg,
.jraUPL {
  opacity: 1 !important;
}
.hnXwpD,
.kLtnvU {
  display: none !important;
}
.hPgVkF,
.dnrUxu {
  display: none !important;
}
.react-datepicker__close-icon {
  right: -56px !important;
}

input[type=file], /* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer;
}
.main-content {
  background-color: rgb(240, 240, 240);
  height: 100%;
  padding: 1.5rem;
}
.admin-header {
  background-color: rgb(244, 129, 32);
  border-bottom: 3px solid #000;
  padding: 20px 30px;
}
.admin-header .header {
  font-size: 1.4rem;
  font-weight: 600;
}
