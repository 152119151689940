.circle-icon{
    /* height: 26px;
    width: 27px; */
    border-radius: 50%;
    display: inline-block;
    background-repeat: no-repeat;
    border: 1px solid #188a88;
    background-size: 100px 100px;
    background-color: #188a88;
    margin-right:12px;
    padding: 4px;
}
.circlespan{
    align-items: center;
    display: flex;
    /* padding: 12px; */
    color:gray;
}
.circle-trainee{
     border-radius: 50%;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: 100px 100px;
    background-color: #188a88;
    margin-right:12px;
    padding: 6px 10px;
    color:'white';
}
.span{
    margin: 5px;
    padding-left: 72px;
    color:gray;
}